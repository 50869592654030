// export default Header;
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../imgs/logo_mod_new.svg";
import "./header.css";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import PersonIcon from "@mui/icons-material/Person";
import getCookie, { removeCookie } from "../../../utiles/Cookie";
import { GetUser } from "../../../utiles/GetUserInfo";
const Header = () => {
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  // const isLoggedIn = Boolean(localStorage.getItem("user-login-token"));
  const location = useLocation();
  const isProfilePage = location.pathname.includes("profile");

  const [click, setClick] = useState(false);
  const { t, i18n } = useTranslation("global");
  document.body.dir = i18n.dir();

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };
  // ------scrolling
  // const scrollToSection = (event, sectionId) => {
  //   event.preventDefault();

  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const [data, setDate] = useState();
  useEffect(() => {
    user !== null &&
      GetUser(user).then((res) => {
        setDate(res);
      });
  }, [user === null]);
  return (
    <>
      <header
        className="py-3"
        style={
          isProfilePage
            ? {
                background: "#1EB4BC",
                paddingBottom: "20px",
                paddingTop: "20px",
              }
            : {}
        }
      >
        {/* <nav className="navbar navbar-expand-lg "></nav> */}

        <nav className="flexSB container ">
          <ul
            className={
              click
                ? "mobile-nav align-items-center"
                : "flexSB mx-3 align-items-center"
            }
            style={{ justifyContent: "end" }}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">{t("header.HOME")}</Link>
            </li>

            <li>
              <HashLink smooth to="/#about">
                {t("header.about")}
              </HashLink>
            </li>
            <li>
              <Link to="/All_events">EVENTS</Link>
            </li>

            <li>
              {user?.token ? (
                <Link
                  to="/"
                  onClick={() => {
                    localStorage.removeItem("user-login-token");
                    removeCookie("user-login-fps");
                  }}
                >
                  LOGOUT
                </Link>
              ) : (
                <Link to="/login">{t("header.LOGIN")}</Link>
              )}
            </li>
            {user?.token && (
              <li
                style={{ background: "#176481", borderRadius: "15px" }}
                className="px-3 d-flex h-100 align-items-center py-2"
              >
                <Link to="/profile" style={{ textWrap: "nowrap" }}>
                  <PersonIcon /> {data?.first_name}
                </Link>
              </li>
            )}
            {/* <li>
              <a href='/Registration'>{t('header.REGISTER')}</a>
            </li> */}

            {/* <button className="btn_lan mx-2 p-1" onClick={() => changeLanguage('en')} style={{height: "2rem" , fontWeight:"600"}}>EN</button>

            <button className="btn_lan mx-2 p-1" onClick={() => changeLanguage('ar')} style={{height: "2rem" , fontWeight:"600"}}>AR</button>  */}
          </ul>
          <div className="start">
            <Link to="/">
              <img src={Logo} className="w-100 h-100" alt=".." />
            </Link>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times"> </i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import JobView from "./JobView";
// import NonJobView from "./NonJobView";
import axios from "axios";
import getCookie from "../../utiles/Cookie.js";

const ViewApplication = (props) => {
  const eventId = props.match.params.id;
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  const [Details, setDetails] = useState({});
useEffect(() => {
  const Applicationdetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/application/event/${eventId}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user?.token}`,
          },
        }
      );
      const data = response.data;
      setDetails(data.result.applicationData);
      console.log("datadata", data.result.applicationData);
      return data;
    } catch (e) {}
  };
  Applicationdetails();
},[eventId])

  // const jobState = Details.hasOwnProperty('job');
  return (
    <>
        <JobView eventId={eventId} />

     

    </>
  );
};

export default ViewApplication;

import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { Button } from "react-bootstrap";
import upload from "../../imgs/upload.png";
import { CheckCircleOutline } from "@mui/icons-material";
import { t } from "i18next";
export default function View({ fetchHackathonData,token, data }) {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [successUpload, setsuccessUpload] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [touched, setTouched] = useState(false);
  const handleFileChange = (event) => {
    setFileError(null);
    setError("");
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {
      setFileError("File size exceeds 1MB. Please select a smaller file.");
      setFile(null);
    } else {
      setFile(selectedFile);
      setFileError(null);
      setError("");
    }
  };

  const validateLink = (inputLink) => {
    if (!inputLink.trim()) {
      return ""; 
    }
    try {
      new URL(inputLink);
      return ""; 
    } catch (_) {
      return "Please enter a valid URL."; 
    }
  };

  const handleLinkChange = (event) => {
    const inputLink = event.target.value;
    setLink(inputLink);
    if (touched) {
      setLinkError(validateLink(inputLink));
    }
  };

  const handleBlur = () => {
    setTouched(true);
    setLinkError(validateLink(link));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFileError(null);
    setError("");
    setLinkError("")
    setTouched(true);
    const error = validateLink(link);
    setLinkError(error);
    if (error || !file) {
      if(!file) return setError('file is required')
    else  return;
    }
    const formData = new FormData();
    formData.append("deliverable_file", file);
    if (link.trim()) {
      formData.append("deliverable_link", link); // Only append the link if it's not empty
    }
    setIsUploading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/hackathon/${data._id}`,
        {
          method: "POST",
          redirect: "follow",
          body: formData,
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setsuccessUpload(true);
        fetchHackathonData()
        setTimeout(() => {
          setsuccessUpload(false);
        }, 3000);
      } else {
        response.json().then((data) => {
          console.log(data)
          setError(data.message || "Failed to upload the file.");
        });
      }
    } catch (err) {
      console.error("Error:", err);
      setError("An error occurred while uploading the file.");
    }
    setIsUploading(false);
  };

  return (
    <div className="view container">
      <div className="d-flex justify-content-center mt-5  align-items-center gap-5 flex-wrap">
        <h1 className="heading-text text-md-end text-center  mb-0 flex-fill">
          Hackathon Application
        </h1>
        <p
          className={
            data?.status === "pending"
              ? "pending px-5 m-0"
              : "qualifaid px-5 m-0"
          }
        >
{data?.status?.charAt(0).toUpperCase() + data?.status?.slice(1)}
</p>
      </div>

      <div className="info-container">
        <div className="p-49">
          <div className="primary-info row my-3 mx-0 ">
            <h3 className="p-0">Experiences</h3>
            <div className="primary-info row my-3 " style={{ rowGap: "40px" }}>
              <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  p-0">
                <p className="m-0 p-0 label">
                  Education<span className="start-required">*</span>{" "}
                </p>
                <p className="m-0 p-0 detials">{data?.education}</p>
              </div>
              <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  p-0">
                <p className="m-0 p-0 label">
                  Institution/Company<span className="start-required">*</span>
                </p>
                <p className="m-0 p-0 detials">{data?.company}</p>
              </div>
              <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  p-0">
                <p className="m-0 p-0 label">
                  Current role<span className="start-required">*</span>
                </p>
                <p className="m-0 p-0 detials">{data?.role}</p>
              </div>
              <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2 p-0 ">
                <p className="m-0 p-0 label">
                  Years of experiences<span className="start-required">*</span>
                </p>
                <p className="m-0 p-0 detials">{data?.years_of_experience}</p>
              </div>
              <div className="primary-info__details col-12 my-2  p-0">
                <p className="m-0 p-0 label">
                  Skills/Expertise<span className="start-required">*</span>{" "}
                </p>
                <p className="m-0 p-0 detials">{data?.skills}</p>
              </div>
              <div className="p-0 primary-info__details col-12 my-2  ">
                <p className="m-0 p-0 label">
                  Share your achievements and success indicators
                  <span className="start-required">*</span>{" "}
                </p>
                <p className="m-0 p-0 detials">{data?.achievements}</p>
              </div>
            </div>
          </div>
          <h3>Add your team</h3>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">
              Team’s Name <span className="start-required">*</span>{" "}
            </p>
            <p className="m-0 p-0 detials">{data?.team_name}</p>
          </div>

          {data?.team.map((t, index) => {
            return (
              <div key={index}>
                <h3 className="mt-4">{index==0?'1st member':index==1?'2nd member':'3rd member'}</h3>
                <div
                  className="primary-info row my-3 "
                  style={{ rowGap: "40px" }}
                >
                  <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
                    <p className="m-0 p-0 label">First name</p>
                    <p className="m-0 p-0 detials">{t?.f_name}</p>
                  </div>
                  <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
                    <p className="m-0 p-0 label">Middle name</p>
                    <p className="m-0 p-0 detials">{t?.m_name}</p>
                  </div>
                  <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
                    <p className="m-0 p-0 label">Last Name</p>
                    <p className="m-0 p-0 detials">{t?.l_name}</p>
                  </div>
                  <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
                    <p className="m-0 p-0 label">National ID</p>
                    <p className="m-0 p-0 detials">{t?.National_ID}</p>
                  </div>
                  <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
                    <p className="m-0 p-0 label">E-mail</p>
                    <p className="m-0 p-0 detials">{t?.email}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {data.status == "qualified" && (
          <form className="upload mb-5">
            <div className="row">
              <div className="primary-info__details col-12 col-lg-4 col-sm-6  my-2  ">
                <label htmlFor="fileInput" className="label">
                  Deliverable file <span className="required">*</span>
                  <p style={{ marginTop: "11px" }} className="mb-0">
                    Attach file
                  </p>
                
                </label>
                <p className="w-75 wrap-text">{data?.deliverable_file}</p>
                <label
                  htmlFor="fileInput"
                  className=" d-flex gap-3 align-items-center input-container "
                  role="button"
                >
                  {" "}
                  <div className="input-file d-flex align-items-center gap-4 justify-content-center">
                    <p className="p-0 m-0">{file?.name || "Upload"} </p>
                    <img src={upload} width={20} height={20} alt="upload" />
                  </div>
                  <p className="p-0 m-0">PDF, Images</p>
                </label>

                <input
                  hidden
                  type="file"
                  id="fileInput"
                  accept=".pdf,.png,.jpg,.jpeg,.webp,.svg"
                  onChange={handleFileChange}
                />
                {fileError ? (
                  <Alert severity="error" style={{ marginTop: "10px" }}>
                    {fileError}
                  </Alert>
                ) : error ? (
                  <Alert severity="error" style={{ marginTop: "10px" }}>
                    {error}
                  </Alert>
                ) : (
                  <></>
                )}
                {successUpload && (
                  <Alert
                    icon={<CheckCircleOutline fontSize="inherit" />}
                    severity="success"
                    className="mt-3"
                  >
                    File uploaded successfully!
                  </Alert>
                )}
              </div>
              <div className="primary-info__details col-12 col-lg-4 col-sm-6  my-2  ">
                <label htmlFor="linkInput" className="label">
                  Deliverable Link
                </label>
                <input
                  type="url"
                  id="linkInput"
                  placeholder="Share your link"
                  className="border-0"
                  value={link}
                  onChange={handleLinkChange}
                  onBlur={handleBlur}
                />
                {linkError && (
                  <Alert severity="error" style={{ marginTop: "10px" }}>
                    {linkError}
                  </Alert>
                )}
              </div>
              <div className="primary-info__details col-12 col-lg-4 col-sm-6  my-2  pt-5">
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isUploading}
                >
                  {" "}
                  {isUploading ? "Uploading..." : "UPDATE"}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import schema from "../../validation/even-registeration-validation.js";
import "./event-registeration-form.css";
import Joi from "joi";
import { useHistory } from "react-router-dom";

import axios from "axios";

const EventRegisteration = () => {
  const [formData, setFormData] = useState({
    job: "",
    student_id_numbrer: "",
    student_id_file: null,
    registration_number: "",
    professional_classification: "",
    health_practitioner_type: "",
    other: "",
  });

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("user-login-token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/profile`,
            {
              headers: {
                "Content-Type": "application/json",
                token: `Bearer ${token}`,
              },
            }
          );
          const data = response.data;
          setUserData(data);
        } catch (e) {
          console.error("Error fetching user data", e);
        }
      }
    };

    fetchUserData();
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    const fieldSchema = Joi.object({ [name]: schema.extract(name) });
    const { error } = fieldSchema.validate({ [name]: value });
    setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, student_id_file: file });
  };
  const [success, setsuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      return;
    }

    const dataToSubmit = new FormData();
    for (const key in formData) {
      if (formData[key]) {
        dataToSubmit.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/application`,
        dataToSubmit,
        {
          headers: {
            "Content-Type": "Application/json",
            token: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        setsuccess(true);
        // alert("You have successfully registered to this event");
      }
    } catch (error) {
      if (error.response.status === 400) {
        alert(
          error.response.data.message || "An error occurred, please try again"
        );
      } else if (error.response.status === 401) {
        alert("You are not authorized, please login first");
      } else {
        alert(
          "An error occurred while processing your request, please try again"
        );
      }
    }
  };
  const navigate = useHistory();

  console.log(success, "sss");
  return (
    <>
      {success === true ? (
        <div className="success_message p-5 text-center my-4">
          <h3>You Have been Registered Successfully!</h3>
          <div className="success_message_text  text-center px-5 d-flex justify-content-center">
            <p
              className=" p-3 text-center mx-5 w-50 "
              style={{ fontWeight: "500", lineHeight: "35px" }}
            >
              Dear Applicant Thank you for applying to the Mental health
              Conference. This message serves as a receipt confirming that we
              have successfully received your application. A confirmation email
              with further details will be sent to you. Please ensure to check
              your email regularly for updates. We look forward to welcoming you
              to the Mental health Conference .
            </p>
          </div>

          <button className="Home_back" onClick={() => navigate.push("/")}>
            Home
          </button>
        </div>
      ) : (
        <>
          <div className="header"></div>
          <h1 className="heading-text text-center mt-5 mb-0">Registration</h1>
          <div className="primary-info row my-3 mx-0 mx-lg-5">
            <p className="primary-info__heading fs-4">Personal Information</p>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="firstName">
                First Name
              </label>
              <div id="firstName">{userData.user?.first_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="middleName">
                Middle Name
              </label>
              <div id="middleName">{userData.user?.middle_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="lastName">
                Last Name
              </label>
              <div id="lastName">{userData.user?.last_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="gender">
                Gender
              </label>
              <div id="gender">{userData.user?.gender || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <div id="email">{userData.user?.email || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="mobileNumber">
                Mobile Number
              </label>
              <div id="mobileNumber">
                {userData.user?.mobile_number || "N/A"}
              </div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="nationalId">
                National Id
              </label>
              <div id="nationalId">{userData.user?.national_id || "N/A"}</div>
            </div>

            <Form.Group controlId="job" className="col-12 col-6 col-sm-4 mb-5">
              <Form.Label className="form-label">Job</Form.Label>
              <Form.Control
                as="select"
                name="job"
                value={formData.job}
                onChange={handleChange}
              >
                <option value="">Select your job</option>
                <option value="Student">Student</option>
                <option value="Professional Practitioner">
                  Professional Practitioner
                </option>
                <option value="Health Practitioner">Health Practitioner</option>
              </Form.Control>
              {errors.job && <p className="text-danger">{errors.job}</p>}
            </Form.Group>
          </div>
          <Form onSubmit={handleSubmit} className="mt-3 mx-0 mx-lg-5">
            {formData.job === "Student" && (
              <div className="row mx-1">
                <Form.Group
                  controlId="student_id_numbrer"
                  className="col-12 col-sm-4 mb-5"
                >
                  <Form.Label className="form-label">
                    Student ID Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="student_id_numbrer"
                    value={formData.student_id_numbrer}
                    onChange={handleChange}
                    placeholder="Enter your Student ID Number"
                  />
                  {errors.student_id_numbrer && (
                    <p className="text-danger">{errors.student_id_numbrer}</p>
                  )}
                </Form.Group>

                <Form.Group
                  controlId="student_id_file"
                  className="col-12 col-sm-4 mb-5"
                >
                  <Form.Label className="form-label">
                    Student ID File
                  </Form.Label>

                  <Form.Control
                    type="file"
                    name="student_id_file"
                    onChange={handleFileChange}
                  />
                  {errors.student_id_file && (
                    <p className="text-danger">
                      "student_id_file" is required, Only JPEG, PNG, or PDF
                      files are allowed.
                    </p>
                  )}
                </Form.Group>
              </div>
            )}
            <div className="row mx-1">
              {formData.job === "Professional Practitioner" && (
                <>
                  <Form.Group
                    controlId="registration_number"
                    className="col-12 col-sm-4 mb-5"
                  >
                    <Form.Label className="form-label">
                      Registration Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="registration_number"
                      value={formData.registration_number}
                      onChange={handleChange}
                      placeholder="SCFHS Number"
                    />
                    {errors.registration_number && (
                      <p className="text-danger">
                        {errors.registration_number}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="professional_classification"
                    className="col-12 col-sm-4 mb-5"
                  >
                    <Form.Label className="form-label">
                      Professional Classification
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="professional_classification"
                      value={formData.professional_classification}
                      onChange={handleChange}
                      placeholder="Enter your Professional classification"
                    />
                    {errors.professional_classification && (
                      <p className="text-danger">
                        {errors.professional_classification}
                      </p>
                    )}
                  </Form.Group>
                </>
              )}

              {formData.job === "Health Practitioner" && (
                <>
                  <Form.Group
                    controlId="health_practitioner_type"
                    className="col-12 col-sm-4 mb-5"
                  >
                    <Form.Label className="form-label">
                      Health Practitioner Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="health_practitioner_type"
                      value={formData.health_practitioner_type}
                      onChange={handleChange}
                    >
                      <option value="">Select one only</option>
                      <option value="Psychiatry">Psychiatry</option>
                      <option value="Nursing">Nursing</option>
                      <option value="Psychology">Psychology</option>
                      <option value="Social Service">Social Service</option>
                      <option value="Others">other</option>
                    </Form.Control>
                    {errors.health_practitioner_type && (
                      <p className="text-danger">
                        {errors.health_practitioner_type}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="registration_number"
                    className="col-12 col-sm-4 mb-5"
                  >
                    <Form.Label className="form-label">
                      Registration Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="registration_number"
                      value={formData.registration_number}
                      onChange={handleChange}
                      placeholder="SCFHS Number"
                    />
                    {errors.registration_number && (
                      <p className="text-danger">
                        {errors.registration_number}
                      </p>
                    )}
                  </Form.Group>
                </>
              )}
            </div>
            {formData.job === "Health Practitioner" &&
              formData.health_practitioner_type === "Others" && (
                <Form.Group controlId="other" className="col-12 col-sm-6 mb-5">
                  <Form.Label className="form-label">other</Form.Label>
                  <Form.Control
                    type="text"
                    name="other"
                    value={formData.other}
                    onChange={handleChange}
                    className="other"
                    placeholder="Enter yourMessage"
                  />
                  {errors.other && (
                    <p className="text-danger">{errors.other}</p>
                  )}
                </Form.Group>
              )}

            <div className="w-100 d-flex justify-content-center my-5">
              <Button type="submit">REGISTER</Button>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default EventRegisteration;

import Joi from "joi";

const fileValidation = Joi.any()
  .custom((value, helpers) => {
    if (
      value &&
      value.type &&
      ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
    ) {
      return value;
    }
    return helpers.message("Only JPEG, PNG, or PDF files are allowed.");
  })
  .required()
  .messages({
    "any.required": "A file is required.",
  });

const schema = Joi.object({
  job: Joi.string().required(),
  student_id_numbrer: Joi.when("job", {
    is: "Student",
    then: Joi.number().min(15).required(),
    otherwise: Joi.optional(),
  }),
  student_id_file: Joi.when("job", {
    is: "Student",
    then: fileValidation,
    otherwise: Joi.optional(),
  }),
  registration_number: Joi.when("job", {
    is: Joi.string().valid("Professional Practitioner", "Health Practitioner"),
    then: Joi.string().min(5).required(),
    otherwise: Joi.optional(),
  }),
  professional_classification: Joi.when("job", {
    is: "Professional Practitioner",
    then: Joi.string()
      .pattern(/^[a-zA-Z\s]+$/)
      .required()
      .messages({
        "string.pattern.base": "Only letters are allowed for Professional Classification.",
      }),
    otherwise: Joi.optional(),
  }),
  health_practitioner_type: Joi.when("job", {
    is: "Health Practitioner",
    then: Joi.string().required(),
    otherwise: Joi.optional(),
  }),
  other: Joi.when("job", {
    is: "Health Practitioner",
    then: Joi.when("health_practitioner_type", {
      is: "Others",
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
    otherwise: Joi.optional(),
  }),
});

export default schema;

import React, { useEffect, useState } from "react";
import axios from "axios";
import getCookie from "../../utiles/Cookie.js";
import { Button } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";

const JobView = (props) => {
  // console.log(props,"ppp");
  const eventId = props.eventId;
  const [userData, setUserData] = useState({});
  const [edit_check, setEdit_check] = useState(false);
  const [loadin, setLoading] = useState(false);
  // const [token, setToken] = useState({});
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  const [id, setID] = useState();

  const handleFileChange = (event) => {
    console.log("event");
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (user.token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/profile`,
            {
              headers: {
                "Content-Type": "application/json",
                token: `Bearer ${user.token}`,
              },
            }
          );
          const data = response.data;
          setUserData(data);
        } catch (e) {
          console.error("Error fetching user data", e);
        }
      }
    };

    fetchUserData();
  }, []);
  const [Details, setDetails] = useState({});
  const [registerNumber, setRegisterNumber] = useState();
  const Applicationdetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/application/event/${eventId}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user.token}`,
          },
        }
      );
      const data = response.data;
      setID(data.result._id);
      setDetails(data.result.applicationData);
      setRegisterNumber(data.result.applicationData.registration_number);
      return data;
    } catch (e) {}
  };
  useEffect(() => {
    Applicationdetails();
    const userDataCookie = getCookie("user-login-fps");
    // setToken(localStorage.getItem("user-login-token"));

    if (userDataCookie) {
      setUserData(JSON.parse(userDataCookie));
    }
  }, [eventId]);
  const [msg, setMsg] = useState();

  const updateRegisterNumber = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API_URL}/application/update-registration-number/${id}`;
    const formData = new FormData();
    formData.append("registration_number", registerNumber);
    if (registerNumber.length < 5) {
      setMsg("Registration number length must be at least 5 characters long");
    } else {
      setLoading(true);
      try {
        const response = await axios.put(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `Bearer ${user.token}`,
          },
        });
        setLoading(false);
        if (response.status === 200 || response.ok) {
          alert("Registration number updated successfully");
          !Details.hasOwnProperty("registration_number") &&
            window.location.reload();

          Applicationdetails();
          setEdit_check(!edit_check);
          console.log("User data updated successfully");
        } else {
          setMsg(
            response.data.errors
              ? response.data.errors[0].msg
              : response.data.message
          );
          console.error(
            "Error posting registration number and file:",
            response.data
          );
        }
      } catch (error) {
        setLoading(false);
        if (!error.response) {
          setMsg(
            "Could you please check your network connection? It seems there may be an issue."
          );
        } else {
          setMsg(error.response.data.message || "An error occurred.");
        }
        console.error("Error:", error);
      }
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [UpdateSuccess, setUpdateSuccess] = useState(false);

  const handleUpdateClick = async () => {
    const formData = new FormData();
    formData.append("board_acceptance_file", selectedFile);
    setLoading(true);
    if (selectedFile == null) {
      setLoading(false);
      alert("International Board Acceptance is required.");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/application/board-acceptance/${id}`,
        {
          method: "POST",
          redirect: "follow",
          body: formData,
          headers: {
            token: `Bearer ${user?.token}`,
          },
        }
      );
      // Read the response body once
      const responseData = await response.json();
      setLoading(false);
      if (response) setLoading(false);
      if (!response.ok) {
        console.log(
          `Error: ${
            responseData.errors ||
            JSON.stringify(responseData.error) ||
            response.status
          }`
        );
      } else {
        setUpdateSuccess(true);
        setDetails(responseData.result.data);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };
  const handleCloseUpdateSucess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateSuccess(false);
  };


  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={UpdateSuccess}
        autoHideDuration={5000}
        onClose={handleCloseUpdateSucess}
        message="International board Acceptance updated successfully."
      />
      <h1 className="heading-text text-center mt-5 mb-0">Application</h1>
      <div className="primary-info row my-3 mx-0 mx-lg-5">
        <p className="primary-info__heading fs-4 fw-bolder">
          Personal Information
        </p>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="firstName">
            First Name
          </label>
          <div id="firstName">{userData.user?.first_name || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="middleName">
            Middle Name
          </label>
          <div id="middleName">{userData.user?.middle_name || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="lastName">
            Last Name
          </label>
          <div id="lastName">{userData.user?.last_name || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="gender">
            Gender
          </label>
          <div id="gender">{userData.user?.gender || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <div id="email">{userData.user?.email || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="mobileNumber">
            Mobile Number
          </label>
          <div id="mobileNumber">{userData.user?.mobile_number || "N/A"}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="nationalId">
            National Id
          </label>
          <div id="nationalId">{userData.user?.national_id || "N/A"}</div>
        </div>
        {/* ================ */}
        {Details.hasOwnProperty("job") === true ? (
          <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
            <label className="form-label" htmlFor="job">
              Job
            </label>
            <div id="job">{Details.job || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="row">
          {/* ================student_id============ */}
          {Details.hasOwnProperty("student_id_numbrer") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="student_id">
                Student Id Number
              </label>
              <div id="student_id">{Details.student_id_numbrer || "N/A"}</div>
            </div>
          ) : (
            <></>
          )}
          {/* =================file */}
          {/* {Details.hasOwnProperty("student_id_file") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="studentIdFile">
                Student ID File
              </label>
              <div id="studentIdFile">{Details.student_id_file || "N/A"}</div>
            </div>
          ) : (
            <></>
          )} */}
        </div>
        <div className="row">
          {Details.hasOwnProperty("registration_number") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="student_id">
                Registeration Number
              </label>
              <form onSubmit={(e) => updateRegisterNumber(e)}>
                {edit_check ? (
                  <input
                    defaultValue={registerNumber}
                    className="w-100 input-register px-2"
                    onChange={(e) => {
                      setRegisterNumber(e.target.value);
                      setMsg(null);
                    }}
                    placeholder="SCFHS Number"
                  ></input>
                ) : (
                  <div id="student_id">
                    {Details.registration_number || "N/A"}
                  </div>
                )}
                {msg && (
                  <Alert severity="error" className="my-2 w-100">
                    {msg}
                  </Alert>
                )}
                {edit_check ? (
                  <button
                    disabled={loadin}
                    style={{
                      backgroundColor: loadin ? "gray" : "#1eb4bc",
                    }}
                    className="register-no mt-2  edit_btn w-100"
                    type="submit"
                  >
                    {loadin ? "...Update" : "Update"}
                  </button>
                ) : (
                  <></>
                )}
              </form>
              {edit_check ? (
                <></>
              ) : (
                <button
                  className="register-no mt-2  edit_btn"
                  type="button"
                  onClick={() => setEdit_check(true)}
                >
                  Edit Registeration Number
                </button>
              )}
            </div>
          ) : Details.job === "Professional Practitioner" ||
            Details.job === "Health Practitioner" ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="student_id">
                Registeration Number
              </label>
              <form onSubmit={(e) => updateRegisterNumber(e)}>
                <input
                  defaultValue={registerNumber}
                  className="w-100 input-register px-2"
                  onChange={(e) => {
                    setRegisterNumber(e.target.value);
                    setMsg(null);
                  }}
                  placeholder="SCFHS Number"
                ></input>

                {msg && (
                  <Alert severity="error" className="my-2 w-100">
                    {msg}
                  </Alert>
                )}

                <button
                  disabled={loadin}
                  style={{
                    backgroundColor: loadin ? "gray" : "#1eb4bc",
                  }}
                  className="register-no mt-2  edit_btn w-100"
                  type="submit"
                >
                  {loadin ? "...Update" : "Update"}
                </button>
              </form>
            </div>
          ) : (
            <></>
          )}
          {/* ================professional certification============ */}
          {Details.hasOwnProperty("professional_classification") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label
                className="form-label"
                htmlFor="professional-certification"
              >
                Professional certification
              </label>
              <div id="professional-certification">
                {Details.professional_classification || "N/A"}
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* ================health practitioner type============ */}
          {Details.hasOwnProperty("health_practitioner_type") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="health-practitioner-type">
                Health Practitioner Type
              </label>
              <div id="health-practitioner-type">
                {Details.health_practitioner_type || "N/A"}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* =============== */}
        {Details.hasOwnProperty("other") === true ? (
          <div className="primary-info__details col-12 col-sm-6 mb-5">
            <label className="form-label" htmlFor="other">
              Other
            </label>
            <div id="other">{Details.other || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}

        {Details.hasOwnProperty("institution") === true ? (
          <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
            <label className="form-label" htmlFor="institution">
              Institution/Company
            </label>
            <div id="institution">{Details.institution || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}

        {Details.hasOwnProperty("position") === true ? (
          <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
            <label className="form-label" htmlFor="currentRole">
              Current role
            </label>
            <div id="currentRole">{Details.position || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}
        {Details.hasOwnProperty("year_of_study") === true ? (
          <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
            <label className="form-label" htmlFor="nationalId">
              Year of study
            </label>
            <div id="nationalId">{Details.year_of_study || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="primary-info row my-3 mx-0 mx-lg-5">
        {Details.hasOwnProperty("personal") === true ? (
          <p className="primary-info__heading fs-4 fw-bolder">
            Personal Information
          </p>
        ) : (
          <></>
        )}
        {Details.hasOwnProperty("gpa") === true ? (
          <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
            <label className="form-label" htmlFor="gpa">
              GPA
            </label>
            <div id="gpa">{Details.gpa || "N/A"}</div>
          </div>
        ) : (
          <></>
        )}

        <div className="row">
          {Details.hasOwnProperty("international_exams") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="international-exams">
                International Exams
                <p className="label-text">
                  Do you have any international exams?
                </p>
              </label>

              <div id="international-exams">
                {Details.international_exams || "N/A"}
              </div>
            </div>
          ) : (
            <></>
          )}
          {Details.hasOwnProperty("international_board_acceptance") === true ? (
            <div className="primary-info__details col-12 col-md-6  my-2 mb-5 ">
              <label className="form-label" htmlFor="international-acceptance">
                International board Acceptance
                <p className="label-text">
                  Have you been accepted in any international board?
                </p>
              </label>

              <div id="international-acceptance">
                {Details.international_board_acceptance || "N/A"}
              </div>
            </div>
          ) : (
            <></>
          )}
          {Details.hasOwnProperty("area_of_interest") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="interestAreas">
                Interest Areas
              </label>
              <div id="interestAreas">{Details.area_of_interest || "N/A"}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row">
          {Details.hasOwnProperty("cv") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="CV">
                CV
              </label>
              <div id="CV">{Details.cv || "N/A"}</div>
            </div>
          ) : (
            <></>
          )}
          {Details.hasOwnProperty("transcript") === true ? (
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="academic-transcript">
                Academic transcript
              </label>
              <div id="academic-transcript">{Details.transcript || "N/A"}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {Details.hasOwnProperty("international_exams") === true ? (
          <div className="row ">
            <div className="primary-info__details col-6 col-sm-2  my-2 mb-5 ">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <div id="name">{Details.international_exams.name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-2  my-2 mb-5 ">
              <label className="form-label" htmlFor="Score">
                Score
              </label>
              <div id="Score">{Details.international_exams.score || "N/A"}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-2  my-2 mb-5 ">
              <label className="form-label" htmlFor="pass-fail">
                Pass/Fail
              </label>
              <div id="pass-fail">
                {Details.international_exams.status || "N/A"}
              </div>
            </div>
            <div className="primary-info__details col-6 col-sm-2  my-2 mb-5 ">
              <label className="form-label" htmlFor="attach-file">
                Attach file
              </label>
              <div id="attach-file">
                {Details.international_exams.attached_file || "N/A"}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {Details.hasOwnProperty("international_board_acceptance") === true ? (
          <div className="file-container d-flex align-items-center justify-content-between p-4">
            <div className="row primary-info__details col-4 col-sm-4  my-2 mb-5">
              <label for="formFile" class="form-label primary-info__details">
                International board Acceptance file
                <p className="label-text">Attach file</p>
              </label>
              <input
                type="file"
                class="form-control "
                id="formFile"
                accept=".pdf, image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
                  const allowedTypes = [
                    "application/pdf",
                    "image/jpeg",
                    "image/png",
                    "image/svg+xml",
                    "image/webp",
                  ]; // Correct WebP and SVG MIME types

                  if (file) {
                    // Check file type
                    if (!allowedTypes.includes(file.type)) {
                      alert(
                        "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
                      );
                      e.target.value = null; // Reset the file input
                      return;
                    }

                    // Check file size
                    if (file.size > maxSizeInBytes) {
                      alert("Max size 1 MB.");
                      e.target.value = null;
                      return;
                    }
                    console.log(e);
                    handleFileChange(e);
                  } else {
                    setSelectedFile(null);
                  }
                }}
              />
            </div>
            <Button
              className="update"
              onClick={() => handleUpdateClick()}
              disabled={loadin}
              style={{
                backgroundColor: loadin ? "gray" : "#1eb4bc",
                border: "0",
              }}
            >
              UPDATE
            </Button>

          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default JobView;

import React, { useEffect, useState } from "react";
import Calender from "../../imgs/calendar_icon.svg";
import Clock from "../../imgs/clock_icon.svg";
import { Link } from "react-router-dom";
import Location from "../../imgs/location_icon.svg";
import "./event.css";
import axios from "axios";
import { Skeleton } from "@mui/material";
import getCookie from "../../utiles/Cookie.js";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Event = (props) => {
  const eventId = props.match.params.id;
  const [event, setEvent] = useState();
  // const [Application, setApplication] = useState({});
  // const [token, setToken] = useState({});
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  const [Details, setDetails] = useState({});


  function formatDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  }
  const formatTime = (from, to) => {
    const format = (num) => (num < 10 ? `0${num}` : num);
    const convertTo12HourFormat = (hours, minutes) => {
      const period = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${format(minutes)} ${period}`;
    };
    const fromHours = Math.floor(from);
    const fromMinutes = (from - fromHours) * 60;
    const toHours = Math.floor(to);
    const toMinutes = (to - toHours) * 60;
    return `${convertTo12HourFormat(
      fromHours,
      fromMinutes
    )} – ${convertTo12HourFormat(toHours, toMinutes)}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchEvent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/event/${eventId}`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response.data.result, "event");
        setEvent(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // ======fetch applications===
    const Applicationdetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/application/event/${eventId}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: `Bearer ${user?.token}`,
            },
          }
        );
        const data = response.data;
        setDetails(data.result);
        
        return data;
      } catch (e) {}
    };
    Applicationdetails();
    fetchEvent();
  }, []);

  return (
    <div className="event_section">
      <div className="container py-5">
        <div className="Title_img my-2 p-5">
          <h2 className="">{event?.name}</h2>
        </div>
        {/* ===info */}
        <div className="row my-5">
          <div className="col-md-8">
            <div className="data">
              <h5 className="my-3">About Event</h5>
              {event ? (
                <p>
                  {event?.details}
                </p>
              ) : (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                </>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="date_time">
              <div className="title py-4  text-center">Date and Time</div>
              <div className="info_link py-4 px-5">
                <div className="d-flex my-4">
                  {/* Calender */}
                  <img src={Calender} alt=".." className="" />

                  {
                    event?.isActive === true ? 
                   <label className="mx-2 w-100"> October 9-10, 2024</label>
                    :
                    <label className="mx-2 w-100">
                    {event ? (
                      formatDate(event?.date)
                    ) : (
                      <Skeleton
                        variant="text"
                        width={"100%"}
                        sx={{ fontSize: "1.2rem" }}
                      />
                    )}
                  </label>
                  }

                </div>
                <div className="d-flex my-4">
                  <img src={Clock} alt=".." className="" />

                  <label className="mx-2 w-100">
                    {event ? (
                      formatTime(+event?.startTime, +event?.endTime)
                    ) : (
                      <Skeleton
                        variant="text"
                        width={"100%"}
                        sx={{ fontSize: "1.2rem" }}
                      />
                    )}
                  </label>
                </div>
                <div className="d-flex my-4">
                  <img src={Location} alt=".." className="" />

                  <label className="mx-2 w-100">
                    {" "}
                    {event ? (
                      event?.city?.charAt(0).toUpperCase() +
                      event?.city?.slice(1)
                    ) : (
                      <Skeleton
                        variant="text"
                        width={"100%"}
                        sx={{ fontSize: "1.2rem" }}
                      />
                    )}
                  </label>
                </div>
                <div className="btn_div my-3 text-center">
                {user?.token ? (
          Object.keys(Details)?.length === 0 && event?.isActive === true ? (
            // <Link to="/event-registeration">Register Now</Link>
            <span className="fw-bold" style={{ color: "red" }}>Registration Closed</span>
          ) : Object.keys(Details)?.length === 0 &&
            event?.isActive === false ? (
            <span style={{ color: "red" }}>Event Closed</span>
          ) : Object.keys(Details)?.length !== 0 ? (
            <Link className="btn_register" to={`/view-application/${event._id}`}>Show Application</Link>
          ) : null
        ) : (
          Object.keys(Details)?.length === 0 && event?.isActive === true ? (  <Link className="btn_register" to="/login">
            Login
            </Link>):(
              <span style={{ color: "red" }}>Event Closed</span>
            )

        )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Agenda=============== */}
      {event?.agenda && event?.agenda.length > 0 && (
        <div className="agenda_section py-4" style={{ direction: "ltr" }}>
          <div className="container">
            <h3 className="my-5">EVENT AGENDA</h3>
            {event?.agenda?.map((e) => {
              return (
                <div className="row my-4">
                  <div className="col-md-3">
                    <div className="d-flex align-items-center">
                      <svg
                        width="20"
                        height="30"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_704_692)">
                          <path
                            d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_704_692">
                            <rect width="20" height="30" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="mx-2">{formatTime(e.from, e.to)}</span>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <h6 className="title_lable">{e.title}</h6>
                    <label className="text_blue">
                      {e.isOnline ? "Will join online" : "Will join offline"}
                    </label>
                    <div className="d-flex align-items-start my-2">
                      <svg
                        width="20"
                        height="30"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                          fill="white"
                        />
                      </svg>
                      <div>
                        <span className="mx-2 d-block">{e.presenter}</span>
                        <p className="mx-2 mb-0">{e.jobDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {
        event?.isActive === true ?
        <>
        <div className="agenda_section py-4" style={{ direction: "ltr" }}>
            <div className="container">
                <h3 className="my-5">EVENT AGENDA</h3>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: "26px" , fontWeight: "500" , color: "#C19F68" , height: "100%", padding: "5px 50px"}} className="nav-link active" id="firstDay-tab" data-bs-toggle="tab" data-bs-target="#firstDay-tab-pane" type="button" role="tab" aria-controls="firstDay-tab-pane" aria-selected="true">Day 1</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: "26px" , fontWeight: "500" , color: "#C19F68", height: "100%", padding: "5px 50px"}} className="nav-link" id="secondDay-tab" data-bs-toggle="tab" data-bs-target="#secondDay-tab-pane" type="button" role="tab" aria-controls="secondDay-tab-pane" aria-selected="false">Day 2</button>
                  </li>
                </ul>
                {/* =========content======== */}
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="firstDay-tab-pane" role="tabpanel" aria-labelledby="firstDay-tab" tabIndex="0">
                  {/* First day content */}
                  {/* <div className="row my-4"> */}
                  <div className="row my-4">
                        {/* <h6 className="title_lable my-3">The First Day</h6> */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:00 - 9:30</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Conference Opening</h6>
                        </div>
                        {/* The First Session */}
                        {/* <h6 className="title_lable">The First Session</h6> */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:35 - 10:00</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Mental Health System: Review and Update</h6>

                            <div className="d-flex ">
                            <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2" style={{width:"fit-content"}}>
                                <p className=" d-block">Dr. Abdulhamid Al Habib</p>
                                <span className=" mb-0">Psychiatry Consultant – Director General of the National Center for Mental Health Promotion </span>
                              </div>
                            </div>
                            <div className="d-flex ">
                            <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Bishi</label>
                                </div>

                            </div>

                        </div>
                        {/* 3 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex ">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:00 - 10:25</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Saudi Mental Health Act: Towards Easier Application</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Mohammed Al Jafer</span>
                                <p className="mx-2 mb-0">Assistant Professor and Consultant of Psychosomatic Medicine, Forensic and Neuropsychiatry – KSU.</p>
                              </div>
                            </div>
                            
                            <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Bishi</label>
                                </div>

                            </div>
                        </div>
                        {/* 4 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:25 - 10:50</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Occupational Burnout</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Mansour Al Dohaiman</span>
                                <p className="mx-2 mb-0">Clinical Social Work Consultant at Mawaddah Center</p>
                              </div>
                            </div>
                            <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Bishi</label>
                                </div>

                            </div>
                        </div>
                        {/* qA */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:50 - 11:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Q & A</h6>
                          <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Bishi</label>
                                </div>

                            </div>
                        </div>
                        {/* 11:00 - 11:25  */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:00 - 11:25 </span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Intolerance of Uncertainty in CBT</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Ahmed Al Hadi</span>
                                <p className="mx-2 mb-0">Associate Professor, Consultant Psychiatrist and Psychotherapist in Department of Psychiatry, King Khalid University Hospital, College 2 of Medicine, King Saud University</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Zakari</label>
                                </div>

                            </div>
                        </div>
                        {/* 11:25 - 11:50  */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:25 - 11:50 </span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Key Elements of Cognitive Behavioural Therapy (CBT) for Social Anxiety Disorder</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Meshal Al Aqeel</span>
                                <p className="mx-2 mb-0">Consultant Psychiatrist and Psychotherapist, King Abdulaziz Medical City for National Guard, Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Zakari</label>
                                </div>

                            </div>
                        </div>

                        {/* qA */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:50 - 12:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Q & A</h6>
                          <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Faisal Al Zakari</label>
                                </div>

                            </div>
                        </div>
                        {/* break */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">12:00 - 13:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Prayer and Lunch Break</h6>
                        </div>
                        <h6 className="title_lable my-3">First day Workshops</h6>
                        {/* work1-1 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">13:00 - 15:00</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Clinical Interview Techniques</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Fahad Al Mansour</span>
                                <p className="mx-2 mb-0">Psychiatrist Consultant at Eradah for Mental health & Addiction o medicine Complex.</p>
                              </div>
                            </div>
                        </div>
                        {/* work1-2 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">15:00 - 17:00</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Coping With Work Stress</h6>
                          <label className="text_blue">
                          </label>
                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Ms. Hind Al Rashoud</span>
                                <p className="mx-2 mb-0">SENIOR PSYCHOLOGIST & WELLNESS PRACTITIONER at King Abdulaziz Medical City</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-start my-3">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Mr. Basil Al Fallaj</span>
                                <p className="mx-2 mb-0">Clinical Psychologist at at The National Guard Hospital</p>
                              </div>
                            </div>
                        </div>
                        
                  </div> 
                  </div>
                {/* </div> */}
                <div className="tab-pane fade" id="secondDay-tab-pane" role="tabpanel" aria-labelledby="secondDay-tab" tabIndex="0">
                  {/* Second day content */}
                  <div className="row my-4">
                        
                        {/* <h6 className="title_lable my-3">The Second Day</h6> */}
                        {/* 9:00 - 9:15 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:00 - 9:15</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Transforming Psychiatry: Harnessing AI for Mental Health</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Ahmed Al Shehri</span>
                                <p className="mx-2 mb-0">Psychiatry Consultant - Adult Mental Health Department at KAMC - NG</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Samira Al Ghamdi</label>
                                </div>

                            </div>
                        </div>
                        {/* 9:15 - 9:30 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:15 - 9:30</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Depressive Disorders: Principles of Diagnosis and Management in Adult</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block"> Dr. Asem Al Aqeel</span>
                                <p className="mx-2 mb-0">Associate Professor of Psychiatry and Consultant Psychiatrsit , College Of Medicine, Imam University.</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Samira Al Ghamdi</label>
                                </div>

                            </div>
                        </div>
                        {/* 9:30 - 9:45 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:30 - 9:45</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Child and Adolescent Mental Health 101</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Shuliweeh Al Enezi</span>
                                <p className="mx-2 mb-0">Psychiatry Consultant - Child and Adolescence Psychiatry at King Saud University Medical City</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Samira Al Ghamdi</label>
                                </div>

                            </div>
                        </div>
                        {/* qA */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">9:45 - 10:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Q & A</h6>
                          <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Samira Al Ghamdi</label>
                                </div>

                            </div>
                        </div>
                        {/* 10:00 - 10:15 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:00 - 10:15</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">What is THE Problem: A Deep Dive into Mental Health Setting Practices</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr.Fahad Al Wahabi</span>
                                <p className="mx-2 mb-0">Consultant, general and geriatric psychiatry at PsychCare Complex, Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Abdulhamid Al Habib</label>
                                </div>

                            </div>
                        </div>
                        {/* 10:15 - 10:30 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:15 - 10:30</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable"> Impact of occupational stress on pharmacists job satisfaction</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Norah Al Solami</span>
                                <p className="mx-2 mb-0">Specialist in Clinical Pharmacology at Eradah complex -Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Abdulhamid Al Habib</label>
                                </div>

                            </div>
                        </div>
                        {/* 10:30 - 10:45 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:30 - 10:45</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">The Effectiveness of a Cognitive Behavior Psychotherapy Program to Improve Mental Health for Women with Breast Cancer in Riyadh</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Amal Al Saif</span>
                                <p className="mx-2 mb-0">Assistant Professor of Psychology at Imam University, College of Social Science</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Abdulhamid Al Habib</label>
                                </div>

                            </div>
                        </div>
                        {/* qA */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">10:45 - 11:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Q & A</h6>
                          <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Abdulhamid Al Habib</label>
                                </div>

                            </div>
                        </div>
                        {/* 11:00 - 11:15 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:00 - 11:15</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Panic Attacks: Towards a Deeper Understanding</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Sami Al Arjan</span>
                                <p className="mx-2 mb-0">Clinical Psychologist at Military Field Medicine - Saudi National Guard - Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex  ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Fahad Al Luhidan</label>
                                </div>

                            </div>
                        </div>
                        {/* 11:15 - 11:30 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:15 - 11:30</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">The Role of Social Services in Improving Employee Mental Health</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Abdulaziz Al Dakheel</span>
                                <p className="mx-2 mb-0">Social Work Consultant - Associate Prof at KSU</p>
                              </div>
                            </div>
                            <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Fahad Al Luhidan</label>
                                </div>

                            </div>
                        </div>
                        {/*11:30 - 11:45  */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:30 - 11:45</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">The Power of your Thoughts: How Thoughts Determine the Quality of your Life</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Ms. Afnan Al Harbi</span>
                                <p className="mx-2 mb-0">Senior Clinical Psychologist at Ministry of Interior-Medical Cities program, Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Fahad Al Luhidan</label>
                                </div>

                            </div>
                        </div>
                        {/* qA */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">11:45 - 12:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Q & A</h6>
                          <div className="d-flex ">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="mx-2">
                              <label className="title_lable d-block">Chairman</label>
                              <label className="title_lable">Dr. Fahad Al Luhidan</label>
                                </div>

                            </div>
                        </div>
                        {/* break */}
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">12:00 - 13:00</span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <h6 className="title_lable">Prayer and Lunch Break</h6>
                        </div>
                        <h6 className="title_lable my-3">Second day Workshops</h6>

                        {/* 13:00 - 15:00	*/}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">13:00 - 15:00</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Behavioral Activation</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Meshal Al Aqeel</span>
                                <p className="mx-2 mb-0">Consultant Psychiatrist and Psychotherapist, King Abdulaziz Medical City for National Guard, Riyadh</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-start my-2">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Dr. Ahmed Al Hadi</span>
                                <p className="mx-2 mb-0">Associate Professor, Consultant Psychiatrist and Psychotherapist in Department of Psychiatry, King Khalid University Hospital, College 2 of Medicine, King Saud University</p>
                              </div>
                            </div>
                        </div>
                        {/* 15:00 - 17:00 */}
                        <div className="col-md-3 my-3">
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="30"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_704_692)">
                                <path
                                  d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_704_692">
                                  <rect width="20" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="mx-2">15:00 - 17:00</span>
                          </div>
                        </div>
                        <div className="col-md-9 my-3">
                          <h6 className="title_lable">Building Therapentic Nurses Patient Relationship</h6>

                            <div className="d-flex align-items-start">
                              <svg
                                width="20"
                                height="30"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                                  fill="white"
                                />
                              </svg>
                              <div style={{width:"fit-content"}}>
                                <span className="mx-2 d-block">Mr. Abdullah Al Yami</span>
                                <p className="mx-2 mb-0">Director of the Nursing Administration at the Eardah Complex and Mental Health in Riyad</p>
                              </div>
                            </div>
                        </div>
                        {/* ============================== */}
                  </div> 
                
                </div>
              </div>


            </div>
        </div>
        </>
        :<></>
      }
    </div>
  );
};

export default Event;

import React, { useEffect, useState } from "react";
import Calender from "../../../imgs/calendar_icon.svg";
import Clock from "../../../imgs/clock_icon.svg";
import { Link } from "react-router-dom";
import Location from "../../../imgs/location_icon.svg";
import EventCard from "../event_card";

import "./allEvent.css";
import axios from "axios";
import { Skeleton } from "@mui/material";

const AllEvents = () => {
  const [eventActive, setEventActive] = useState();
  const [events, setEvents] = useState();

  function formatDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  }
  const formatTime = (from, to) => {
    const format = (num) => (num < 10 ? `0${num}` : num);
    const convertTo12HourFormat = (hours, minutes) => {
      const period = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${format(minutes)} ${period}`;
    };
    const fromHours = Math.floor(from);
    const fromMinutes = (from - fromHours) * 60;
    const toHours = Math.floor(to);
    const toMinutes = (to - toHours) * 60;
    return `${convertTo12HourFormat(
      fromHours,
      fromMinutes
    )} – ${convertTo12HourFormat(toHours, toMinutes)}`;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchEvent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/event/active`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setEventActive(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // ======all-events

    const fetchAllEvent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/event`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setEvents(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEvent();
    fetchAllEvent();
  }, []);
  console.log(events, "allevents");
  console.log(eventActive, "active");
  return (
    <div>
      <div className="allevent_section">
        <div className="container py-2">
          <h2 className="my-3">Events</h2>
          <Link to={`/event/${eventActive?._id}`}>
            <div className="Title_img my-5 p-5">
              <h3 className="my-4">{eventActive?.name}</h3>
              <div className="d-flex">
                <svg
                  width="21"
                  height="27"
                  viewBox="0 0 21 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.2824 1.81299H16.4294V4.12185C16.4294 4.58363 16.1206 4.89147 15.8118 4.89147C15.503 4.89147 15.1941 4.58363 15.1941 4.12185V1.81299H5.31179V4.12185C5.31179 4.58363 5.00297 4.89147 4.69415 4.89147C4.38532 4.89147 4.0765 4.58363 4.0765 4.12185V1.81299H2.22356C1.29709 1.81299 0.617676 2.8135 0.617676 4.12185V6.89249H20.3824V4.12185C20.3824 2.8135 19.2706 1.81299 18.2824 1.81299ZM0.617676 8.5087V22.5928C0.617676 23.9781 1.29709 24.9016 2.28532 24.9016H18.3441C19.3324 24.9016 20.4441 23.9011 20.4441 22.5928V8.5087H0.617676ZM6.11473 21.4383H4.63238C4.38532 21.4383 4.13826 21.2075 4.13826 20.8226V18.8986C4.13826 18.5907 4.32356 18.2829 4.63238 18.2829H6.1765C6.42356 18.2829 6.67062 18.5138 6.67062 18.8986V20.8226C6.60885 21.2075 6.42356 21.4383 6.11473 21.4383ZM6.11473 14.5117H4.63238C4.38532 14.5117 4.13826 14.2809 4.13826 13.896V11.972C4.13826 11.6641 4.32356 11.3563 4.63238 11.3563H6.1765C6.42356 11.3563 6.67062 11.5872 6.67062 11.972V13.896C6.60885 14.2809 6.42356 14.5117 6.11473 14.5117ZM11.0559 21.4383H9.51179C9.26473 21.4383 9.01768 21.2075 9.01768 20.8226V18.8986C9.01768 18.5907 9.20297 18.2829 9.51179 18.2829H11.0559C11.303 18.2829 11.55 18.5138 11.55 18.8986V20.8226C11.55 21.2075 11.3647 21.4383 11.0559 21.4383ZM11.0559 14.5117H9.51179C9.26473 14.5117 9.01768 14.2809 9.01768 13.896V11.972C9.01768 11.6641 9.20297 11.3563 9.51179 11.3563H11.0559C11.303 11.3563 11.55 11.5872 11.55 11.972V13.896C11.55 14.2809 11.3647 14.5117 11.0559 14.5117ZM15.9971 21.4383H14.453C14.2059 21.4383 13.9589 21.2075 13.9589 20.8226V18.8986C13.9589 18.5907 14.1441 18.2829 14.453 18.2829H15.9971C16.2441 18.2829 16.4912 18.5138 16.4912 18.8986V20.8226C16.4912 21.2075 16.3059 21.4383 15.9971 21.4383ZM15.9971 14.5117H14.453C14.2059 14.5117 13.9589 14.2809 13.9589 13.896V11.972C13.9589 11.6641 14.1441 11.3563 14.453 11.3563H15.9971C16.2441 11.3563 16.4912 11.5872 16.4912 11.972V13.896C16.4912 14.2809 16.3059 14.5117 15.9971 14.5117Z"
                    fill="white"
                  />
                </svg>
                <h5 className="mx-3">
                  {/* {formatDate(eventActive?.date)} */}
                  October 9-10, 2024
                </h5>
              </div>
              <div className="d-flex">
                <svg
                  width="22"
                  height="27"
                  viewBox="0 0 22 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1393_1104)">
                    <path
                      d="M11 0.608398C7.13432 0.608398 3.91016 4.31217 3.91016 9.04117C3.91016 10.8403 4.36468 12.4445 5.23699 13.9478L10.4574 23.6368C10.7107 24.1078 11.2898 24.1069 11.5426 23.6368L16.7857 13.9148C17.6392 12.4797 18.0898 10.7945 18.0898 9.04117C18.0898 4.39134 14.9093 0.608398 11 0.608398ZM11 12.8742C9.22311 12.8742 7.77734 11.1546 7.77734 9.04117C7.77734 6.92771 9.22311 5.20809 11 5.20809C12.7769 5.20809 14.2227 6.92771 14.2227 9.04117C14.2227 11.1546 12.7769 12.8742 11 12.8742Z"
                      fill="white"
                    />
                    <path
                      d="M16.0387 18.2251L12.7932 24.2603C11.9531 25.8181 10.0422 25.813 9.20614 24.2617L5.95538 18.2267C3.09521 19.0132 1.33203 20.4541 1.33203 22.176C1.33203 25.1638 6.31331 26.7757 11 26.7757C15.6867 26.7757 20.668 25.1638 20.668 22.176C20.668 20.4529 18.9023 19.0112 16.0387 18.2251Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1393_1104">
                      <rect
                        width="22"
                        height="26.1671"
                        fill="white"
                        transform="translate(0 0.608398)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <h5 className="mx-3">Mövenpick Hotel, Riyadh</h5>
              </div>
            </div>
          </Link>

          {/* ===events-card */}
          {/* <EventCard/> */}
          <div className="my-5">
            <div className="row justify-content-between flex-wrap gap-5">
              {events?.map((e) => {
                return (
                  <Link to={`/event/${e._id}`} className=" col-md-5 col-12">
                    <div className="card-event_All d-flex gap-4">
                      <div className="img"></div>
                      <div
                        className="d-flex flex-column justify-content-between flex-fill"
                        style={{ padding: "16.8px 0" }}
                      >
                        {events ? (
                          <p className="card-name">{e.name}</p>
                        ) : (
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1.2rem" }}
                          />
                        )}
                        <div
                          className="d-flex flex-column"
                          style={{ gap: "25px" }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <svg
                              width="28"
                              height="27"
                              viewBox="0 0 28 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.3764 0.705872H21.9058V3.2647C21.9058 3.77646 21.4941 4.11764 21.0823 4.11764C20.6705 4.11764 20.2588 3.77646 20.2588 3.2647V0.705872H7.08231V3.2647C7.08231 3.77646 6.67055 4.11764 6.25878 4.11764C5.84702 4.11764 5.43525 3.77646 5.43525 3.2647V0.705872H2.96466C1.72937 0.705872 0.823486 1.8147 0.823486 3.2647V6.33528H27.1764V3.2647C27.1764 1.8147 25.6941 0.705872 24.3764 0.705872ZM0.823486 8.12646V23.7353C0.823486 25.2706 1.72937 26.2941 3.04702 26.2941H24.4588C25.7764 26.2941 27.2588 25.1853 27.2588 23.7353V8.12646H0.823486ZM8.1529 22.4559H6.17643C5.84702 22.4559 5.5176 22.2 5.5176 21.7735V19.6412C5.5176 19.3 5.76466 18.9588 6.17643 18.9588H8.23525C8.56466 18.9588 8.89407 19.2147 8.89407 19.6412V21.7735C8.81172 22.2 8.56466 22.4559 8.1529 22.4559ZM8.1529 14.7794H6.17643C5.84702 14.7794 5.5176 14.5235 5.5176 14.097V11.9647C5.5176 11.6235 5.76466 11.2823 6.17643 11.2823H8.23525C8.56466 11.2823 8.89407 11.5382 8.89407 11.9647V14.097C8.81172 14.5235 8.56466 14.7794 8.1529 14.7794ZM14.7411 22.4559H12.6823C12.3529 22.4559 12.0235 22.2 12.0235 21.7735V19.6412C12.0235 19.3 12.2705 18.9588 12.6823 18.9588H14.7411C15.0705 18.9588 15.4 19.2147 15.4 19.6412V21.7735C15.4 22.2 15.1529 22.4559 14.7411 22.4559ZM14.7411 14.7794H12.6823C12.3529 14.7794 12.0235 14.5235 12.0235 14.097V11.9647C12.0235 11.6235 12.2705 11.2823 12.6823 11.2823H14.7411C15.0705 11.2823 15.4 11.5382 15.4 11.9647V14.097C15.4 14.5235 15.1529 14.7794 14.7411 14.7794ZM21.3294 22.4559H19.2705C18.9411 22.4559 18.6117 22.2 18.6117 21.7735V19.6412C18.6117 19.3 18.8588 18.9588 19.2705 18.9588H21.3294C21.6588 18.9588 21.9882 19.2147 21.9882 19.6412V21.7735C21.9882 22.2 21.7411 22.4559 21.3294 22.4559ZM21.3294 14.7794H19.2705C18.9411 14.7794 18.6117 14.5235 18.6117 14.097V11.9647C18.6117 11.6235 18.8588 11.2823 19.2705 11.2823H21.3294C21.6588 11.2823 21.9882 11.5382 21.9882 11.9647V14.097C21.9882 14.5235 21.7411 14.7794 21.3294 14.7794Z"
                                fill="#005B6D"
                              />
                            </svg>

                            {events ? (
  e.isActive === true ? (
    <p className="p-0 m-0 card-date">October 9-10, 2024</p>
  ) : (
    <p className="p-0 m-0 card-date">{formatDate(e.date)}</p>
  )
) : (
  <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
)}
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <svg
                              width="28"
                              height="27"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1239_393)">
                                <path
                                  d="M11 0C7.13432 0 3.91016 3.11395 3.91016 7.08984C3.91016 8.60243 4.36468 9.95122 5.23699 11.2151L10.4574 19.3611C10.7107 19.7571 11.2898 19.7563 11.5426 19.3611L16.7857 11.1874C17.6392 9.98078 18.0898 8.56397 18.0898 7.08984C18.0898 3.1805 14.9093 0 11 0ZM11 10.3125C9.22311 10.3125 7.77734 8.86673 7.77734 7.08984C7.77734 5.31296 9.22311 3.86719 11 3.86719C12.7769 3.86719 14.2227 5.31296 14.2227 7.08984C14.2227 8.86673 12.7769 10.3125 11 10.3125Z"
                                  fill="#4D8C99"
                                />
                                <path
                                  d="M16.0387 14.8111L12.7932 19.8852C11.9531 21.195 10.0422 21.1907 9.20614 19.8864L5.95538 14.8125C3.09521 15.4737 1.33203 16.6852 1.33203 18.1328C1.33203 20.6449 6.31331 22 11 22C15.6867 22 20.668 20.6449 20.668 18.1328C20.668 16.6841 18.9023 15.4721 16.0387 14.8111Z"
                                  fill="#4D8C99"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1239_393">
                                  <rect width="22" height="22" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>

                            {events ? (
                              <p className="p-0 m-0 card-location">{e.city}</p>
                            ) : (
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1.2rem", width: "100%" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEvents;
